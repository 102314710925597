<h1>404 Not Found</h1>
<p>Sorry, but I couldn’t find <strong>{pathname}</strong>.</p>
<p>{suggestion}</p>
<script>
  const pathname = document.location.pathname
  const suggestion = pathname === "/people/bevzilla"
    ? "Although... since *she's* not *here*, let me tell you about everything I had to cut from the interview.... 😈"
    : "Maybe check under the couch cushions?"
</script>
<style>
  strong {
    color: var(--warn2);
  }
  strong::before, strong::after {
    content: "<";
    color: var(--warn3);
    margin-left: 0.5ch;
    margin-right: 0.5ch;
  }
  strong::after {
    content: ">";
  }
</style>
