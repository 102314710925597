<header id="top">
  <AppLogo />
  <HeaderNav />
</header>
<main id="main">
  <article>
    <slot />
  </article>
</main>
<AppFooter />

<script>
  import { getContext } from "svelte"

  import AppLogo from "../components/header-logo.svelte"
  import AppFooter from "../components/footer.svelte"
  import HeaderNav from "../components/header-navigation.svelte"
  import FooterNav from "../components/footer-navigation.svelte"

  const navLinks = getContext("navLinks")
</script>

<style>
  main {
    background-color: var(--black5);
    color: var(--white9);
    padding: 0.25em;
    flex: 1 1 auto;
  }
  article {
    width: 800px;
    max-width: 80vw;
    margin: 0 auto;
  }
  header {
    background-color: var(--black8);
    color: var(--white8);
  }
</style>
