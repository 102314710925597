<nav>
  <ul>
    {#each $navLinks as { url, label, active }}
    <li>
      <a href="{url}" class:active>
        <span>{label}</span>
      </a>
    </li>
    {/each}
    <li>
      <a href="#top">
        <span>Top</span>
      </a>
    </li>
  </ul>
</nav>

<script>
  import { getContext } from "svelte"
  const navLinks = getContext("navLinks")
</script>

<style>
  nav {
    padding: 0;
    margin: var(--footer-margin);
  }

  ul {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    flex: 1 1;
    margin: 0;
    padding: 0.25em;
    text-align: center;
  }

  a {
    color: inherit;
    padding: 0 0.5em;
    text-decoration: none;
    border-bottom: 0.25em solid transparent;
  }

  a:hover {
    border-bottom: 0.25em solid var(--white5);
  }

  a.active {
    border-bottom: 0.25em solid var(--white);
  }
</style>
