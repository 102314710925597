<h1>Episodes</h1>
<h2>Episode 1: Ally, Affable Ally</h2>
<p>In the <a href="https://anchor.fm/affable/episodes/Ally--Affable-Ally-e88j8t">Premier Episode</a> of the <em>Affable Ally Podcast</em>, I sit down with friend of the show, <a href="/people/bevzilla">BevZilla</a>. We discuss one of the most prolific movie franchises of all time — Bond, James Bond. In Part 1, we discuss the film’s iconic tropes, infamous character names, and the unexpectedly explicit opening to <a href="https://www.imdb.com/title/tt0086034/">Octopussy</a> – the 13th film in the series, which was released in 1983. Along the way, I’ll spend some time embarrassing myself in an attempt to explain just why the world needs yet another podcast!</p>
<p>The show is available immediately on <a href="https://anchor.fm/affable/episodes/Ally--Affable-Ally-e88j8t">anchor.fm</a> and should be appearing on your pod-catcher of choice soon.</p>
<iframe title="Episode 1: Ally, Affable Ally" src="https://anchor.fm/affable/embed/episodes/Ally--Affable-Ally-e88j8t" frameborder="0" scrolling="no"></iframe>
<style>
  iframe {
    margin: 1em auto 2em;
    height: 102px;
    width: 400px;
    overflow: clip clip;
    max-width: 90%;
    padding: 0;
    background-color: black;
    display: block;
    box-shadow: 12px 12px 6px 3px var(--black4), inset 1em 1em 0.5em 1em var(--balck4);
    border: 2px solid var(--color3);
    border-radius: 1em;
  }
</style>
